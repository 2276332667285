// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./xSpFS8sUh-0.js";
import * as localizedValues1 from "./xSpFS8sUh-1.js";

const cycleOrder = ["oHTT6owB_", "iIwfM29h2"];

const serializationHash = "framer-NqdAO"

const variantClassNames = {iIwfM29h2: "framer-v-1n8drqk", oHTT6owB_: "framer-v-1wciojk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {qFQHIOpJU: localizedValues1, VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Darker: "oHTT6owB_", Lighter: "iIwfM29h2"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "oHTT6owB_"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "oHTT6owB_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1wciojk", className, classNames)} data-framer-name={"Darker"} layoutDependency={layoutDependency} layoutId={"oHTT6owB_"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "10px 10px 20px 0px rgba(0, 0, 0, 0.15)", ...style}} {...addPropertyOverrides({iIwfM29h2: {"data-framer-name": "Lighter"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 0 + (0 + 0 + (((((componentViewport?.height || 275) - 0) - 275) / 1) * 0)))), pixelHeight: 730, pixelWidth: 254, sizes: "95px", src: "https://framerusercontent.com/images/gN2xtSyWFR2HMFJZfYUOURhavF8.png", srcSet: "https://framerusercontent.com/images/gN2xtSyWFR2HMFJZfYUOURhavF8.png 254w"}} className={"framer-2lzo7c"} layoutDependency={layoutDependency} layoutId={"vc5mf3lSr"} {...addPropertyOverrides({iIwfM29h2: {background: {alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 0 + (0 + 0 + (((((componentViewport?.height || 275) - 0) - 275) / 1) * 0)))), pixelHeight: 730, pixelWidth: 254, sizes: "95px", src: "https://framerusercontent.com/images/MkTO9Rc7ZOJbgH5rpyRsIShXSYY.png", srcSet: "https://framerusercontent.com/images/MkTO9Rc7ZOJbgH5rpyRsIShXSYY.png 254w"}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NqdAO.framer-15ihq9t, .framer-NqdAO .framer-15ihq9t { display: block; }", ".framer-NqdAO.framer-1wciojk { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; height: 275px; justify-content: space-between; overflow: hidden; padding: 0px; position: relative; width: 95px; will-change: var(--framer-will-change-override, transform); }", ".framer-NqdAO .framer-2lzo7c { flex: none; height: 275px; overflow: hidden; position: relative; width: 95px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 275
 * @framerIntrinsicWidth 95
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"iIwfM29h2":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerxSpFS8sUh: React.ComponentType<Props> = withCSS(Component, css, "framer-NqdAO") as typeof Component;
export default FramerxSpFS8sUh;

FramerxSpFS8sUh.displayName = "Card Copy";

FramerxSpFS8sUh.defaultProps = {height: 275, width: 95};

addPropertyControls(FramerxSpFS8sUh, {variant: {options: ["oHTT6owB_", "iIwfM29h2"], optionTitles: ["Darker", "Lighter"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerxSpFS8sUh, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})